// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shareButton--zZs1X {
  --bo-ui-button-svg-height: 24px;
  --bo-ui-button-svg-width: 24px;
}
.bodalButtons--Fbqbp {
  align-content: center;
  justify-content: center!important;
  margin-bottom: 3rem;
}
.bodalButtons--Fbqbp > span {
  position: absolute;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/shareLinkButton/shareLinkButton.module.less"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,8BAAA;AACF;AAEA;EACE,qBAAA;EACA,iCAAA;EACA,mBAAA;AAAF;AAHA;EAMI,kBAAA;EACA,QAAA;AAAJ","sourcesContent":[".shareButton {\n  --bo-ui-button-svg-height: 24px;\n  --bo-ui-button-svg-width: 24px;\n}\n\n.bodalButtons {\n  align-content: center;\n  justify-content: center!important;\n  margin-bottom: 3rem;\n\n  > span {\n    position: absolute;\n    right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareButton": `shareButton--zZs1X`,
	"bodalButtons": `bodalButtons--Fbqbp`
};
export default ___CSS_LOADER_EXPORT___;
