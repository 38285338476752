// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.googleClassroomLink--GWJfJ {
  padding-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/shareLinkButton/googleClassroom/GoogleClassroomShareLink.module.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ","sourcesContent":[".googleClassroomLink {\n    padding-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"googleClassroomLink": `googleClassroomLink--GWJfJ`
};
export default ___CSS_LOADER_EXPORT___;
